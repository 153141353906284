'use client';

import { forwardRef, Ref, useMemo } from 'react';
import ReactSelect, {
  ClassNamesConfig,
  DropdownIndicatorProps,
  GroupBase,
  Props as SelectProps,
} from 'react-select';

import classNames from 'classnames';

import AngleDown from '@uikit/icons/AngleDown';

export type { GroupBase, Props as SelectProps } from 'react-select';

const SelectDropdownIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => {
  return (
    <AngleDown
      className={classNames('icon', props.getClassNames('dropdownIndicator', props), '-my-2.5')}
    />
  );
};

export const getSelectClassNames = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  classNameControll?: string,
): ClassNamesConfig<Option, IsMulti, Group> => {
  return {
    container: () => {
      return classNames('min-w-[150px]');
    },
    control: ({ isFocused, isDisabled, selectProps: { isReadonly, isError, shape } }) => {
      let stateClassName = 'hover:text-field-hover';

      if (isReadonly) {
        stateClassName = 'text-field-readonly';
      } else if (isDisabled) {
        stateClassName = 'text-field-disabled';
      } else if (isFocused) {
        stateClassName = 'text-field-focus';
      } else if (isError) {
        stateClassName = 'text-field-error';
      }

      return classNames(
        'text-field-base',
        shape === 'pill' && 'text-field-shape-pill',
        !isDisabled && !isReadonly && '!cursor-pointer',
        stateClassName,
        classNameControll,
      );
    },
    indicatorsContainer: ({ isDisabled, selectProps }) => {
      return classNames((isDisabled || selectProps.readOnly) && '!hidden');
    },
    indicatorSeparator: () => {
      return 'hidden';
    },
    dropdownIndicator: ({ selectProps }) => {
      return classNames(
        'text-primary !transition-transform origin-center',
        selectProps.menuIsOpen && 'rotate-180',
      );
    },
    menu: () => {
      return classNames('mt-2 rounded bg-neutral-50 shadow-popover');
    },
    menuList: () => {
      return classNames('!max-h-[180px] py-2 divide-y divide-primary-100');
    },
    option: ({ isSelected, isFocused }) => {
      let bg = 'hover:bg-primary-100';

      if (isSelected) {
        bg = 'bg-primary-300';
      } else if (isFocused) {
        bg = 'bg-primary-100';
      }

      return classNames('!flex items-center min-h-[40px] px-4 py-1 !cursor-pointer !text-xs', bg);
    },
    clearIndicator: () => {
      return classNames('-my-2.5');
    },
    noOptionsMessage: () => {
      return classNames('flex items-center justify-center text-xs min-h-[40px]');
    },
    placeholder: () => {
      return classNames('text-field-placeholder');
    },
  };
};

const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  {
    id,
    classNameControll,
    ...props
  }: SelectProps<Option, IsMulti, Group> & { classNameControll?: string },
  ref: Ref<HTMLElement>,
) => {
  // TODO: find a way to extract it in constant and making types work correctly
  const selectClassNames: ClassNamesConfig<Option, IsMulti, Group> = useMemo(
    () => getSelectClassNames(classNameControll),
    [classNameControll],
  );
  const selectComponents = useMemo(() => {
    return {
      DropdownIndicator: SelectDropdownIndicator,
      ...props.components,
    };
  }, [props.components]);

  return (
    <ReactSelect
      components={selectComponents}
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ref={ref}
      inputId={id}
      isDisabled={props.isDisabled || props.disabled || props.readOnly}
      classNames={selectClassNames}
      unstyled
    />
  );
};

export default forwardRef(Select);
