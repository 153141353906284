import { ComponentPropsWithoutRef, forwardRef } from 'react';

import classNames from 'classnames';

import { useModalContext } from '@uikit';

const ModalClose = forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<'button'>>(
  ({ className, ...props }, ref) => {
    const { setOpen } = useModalContext();

    return (
      <button
        type="button"
        className={classNames('focus-visible:outline-none', className)}
        {...props}
        ref={ref}
        onClick={() => setOpen(false)}
        data-qa="modal-close-button"
      />
    );
  },
);

export default ModalClose;
