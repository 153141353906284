import { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from 'react';

import { createSafeContext, useSafeContext } from '@uikit/utils/context';

import { useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';

export const MODAL_ANIMATION_DURATION = 500;

export interface ModalOption {
  initialOpen?: boolean;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  isClosing?: boolean; // for having an animation while using useModalController
  setIsClosing?: (value: boolean) => void; // for having an animation while using useModalController
  isAnimationEnabled?: boolean;
}

export function useModal({
  initialOpen = false,
  isOpen: controlledOpen,
  onOpenChange: setControlledOpen,
  isClosing: controllerIsClosing,
  setIsClosing: controllerSetIsClosing,
  isAnimationEnabled = true,
}: ModalOption) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();
  const [isClosing, setIsClosing] = useState(false);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const closing = controllerIsClosing ?? isClosing;
  const setClosing = controllerSetIsClosing ?? setIsClosing;
  const data = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const context = data.context;
  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context, {
    outsidePress: false,
    outsidePressEvent: 'click',
  });
  const role = useRole(context);
  const interactions = useInteractions([click, dismiss, role]);
  const animatedSetOpen = useCallback(
    (value: boolean) => {
      if (value) {
        return setOpen(true);
      }

      setClosing(true);

      setTimeout(() => {
        setOpen(false);
        setClosing(false);
      }, MODAL_ANIMATION_DURATION);
    },
    [setOpen, setClosing],
  );

  return useMemo(
    () => ({
      open,
      setOpen: isAnimationEnabled ? animatedSetOpen : setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      isClosing: closing,
    }),
    [
      open,
      setOpen,
      animatedSetOpen,
      interactions,
      data,
      labelId,
      descriptionId,
      closing,
      isAnimationEnabled,
    ],
  );
}

interface ContextType extends ReturnType<typeof useModal> {
  setLabelId: Dispatch<SetStateAction<string | undefined>>;
  setDescriptionId: Dispatch<SetStateAction<string | undefined>>;
}

const ModalContext = createSafeContext<ContextType>();

export const useModalContext = () => useSafeContext(ModalContext, 'ModalContext');

export interface ModalProps extends ModalOption {
  children: ReactNode;
}

export function Modal({ children, ...options }: ModalProps) {
  const modal = useModal(options);
  return <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>;
}

export interface ModalControllerProps {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  isClosing: boolean;
  setIsClosing: Dispatch<SetStateAction<boolean>>;
  isAnimationEnabled: boolean;
}

export interface ModalController {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export interface MainModalController extends ModalController {
  isOpen: boolean;
  props: ModalControllerProps;
}

export const useModalController = (initialIsOpen = false): MainModalController => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [isClosing, setIsClosing] = useState(false);
  const controller: ModalController = useMemo(
    () => ({
      open: () => setIsOpen(true),
      close: () => {
        setIsClosing(true);

        setTimeout(() => {
          setIsOpen(false);
          setIsClosing(false);
        }, MODAL_ANIMATION_DURATION);
      },
      toggle: () => setIsOpen((oldState) => !oldState),
    }),
    [],
  );
  const onOpenChange = useCallback(
    (value: boolean) => {
      if (value) {
        controller.open();
      } else {
        controller.close();
      }
    },
    [controller],
  );
  const props: ModalControllerProps = {
    isOpen,
    onOpenChange,
    isClosing,
    setIsClosing,
    isAnimationEnabled: false,
  };

  return {
    isOpen,
    ...controller,
    props,
  };
};
