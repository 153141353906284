import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { ModalClose } from '@uikit';

import Close from '@uikit/icons/Close';

export default function ModalCloseButton({
  className,
  ...props
}: ComponentPropsWithoutRef<'button'>) {
  return (
    <ModalClose
      className={classNames(
        'text-secondary self-end hover:bg-secondary-100 transition rounded-full p-1 mb-1 h-8',
        className,
      )}
      {...props}
    >
      <span className="icon">
        <Close />
      </span>
    </ModalClose>
  );
}
