import { forwardRef, HTMLProps, useLayoutEffect } from 'react';

import classNames from 'classnames';

import { useModalContext } from '@uikit';

import useUniqueId from '@uikit/utils/hooks/useUniqueId';

const ModalDescription = forwardRef<HTMLParagraphElement, HTMLProps<HTMLParagraphElement>>(
  ({ id, className, ...props }, ref) => {
    const { setDescriptionId } = useModalContext();
    const modalDescriptionId = useUniqueId(id);

    // Only sets `aria-describedby` on the Modal root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setDescriptionId(modalDescriptionId);
      return () => setDescriptionId(undefined);
    }, [modalDescriptionId, setDescriptionId]);

    return (
      <p
        ref={ref}
        id={modalDescriptionId}
        className={classNames('modal-description', className)}
        {...props}
      />
    );
  },
);

export default ModalDescription;
